import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AltinnAuthRequest } from '../../unientities';
import { AltinnAuthenticationData } from '../../models/AltinnAuthenticationData';
import { AltinnAuthenticationModal2 } from '@app/components/common/modals/altinn-authentication-modal/altinn-authentication-modal';
import { UniModalService } from '@uni-framework/uni-modal/modalService';
import { UniHttp } from '@uni-framework/core/http';

@Injectable({ providedIn: 'root' })
export class AltinnAuthenticationService {
    authData: AltinnAuthenticationData;
    hasImplicitAuth: boolean = false;

    constructor(
        private http: HttpClient,
        private unihttp: UniHttp,
        private modalService: UniModalService,
    ) {}

    authenticate(): Observable<AltinnAuthenticationData> {
        if (this.authData && this.authData.isValid()) {
            return of(this.authData);
        } else {
            this.authData = undefined;
            return this.modalService
                .open(AltinnAuthenticationModal2, {
                    modalConfig: {
                        getPinMessage: (data) => this.getPinMessage(data),
                    },
                })
                .onClose.pipe(
                    tap((authData) => {
                        if (authData) {
                            this.authData = authData;
                        }
                    }),
                );
        }
    }

    clearAuthData() {
        this.authData = undefined;
    }

    getPinMessage(altinnAuthRequest: AltinnAuthRequest): Observable<any> {
        return this.http.post('/api/biz/altinn?action=get-pin-message', altinnAuthRequest);
    }

    testImplicitAuthentication() {
        this.unihttp
            .asGET()
            .usingBusinessDomain()
            .withEndPoint('altinn?action=test-implicit-authentication')
            .send()
            .pipe(map((res) => (this.hasImplicitAuth = res.body)))
            .subscribe();
    }
}
