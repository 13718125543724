import { from, forkJoin, Observable, of } from 'rxjs';

import { switchMap, map } from 'rxjs/operators';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { Altinn, AltinnReceipt, TaxCardReadStatus, A06Options } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { Injectable } from '@angular/core';
import { RequestMethod } from '@uni-framework/core/http';
import { AltinnAuthenticationData } from '../../models/AltinnAuthenticationData';
import { FieldType } from '../../../framework/ui/uniform/index';
import { AltinnAuthenticationService } from './altinnAuthenticationService';

export interface BarnepassLeveranse {
    ID: number;
    inntektsaar: number;
    oppgave: Array<OppgaveBarnepass>;
}

export interface OppgaveBarnepass {
    foedselsnummer: string;
    navn: string;
    paaloeptBeloep: number;
    email: string;
}

export interface ThirdPartyData {
    year: number;
    items: Array<ThirdPartyItem>;
}
export interface ThirdPartyItem {
    number: string;
    name: string;
    amount: number;
}

export type TaxRequestOption =
    | 'ALL_EMPS_WITHOUT_ENDDATE'
    | 'ALL_EMPS'
    | 'EMPS_WITHOUT_TAXINFO'
    | 'EMPS_IN_CATEGORY'
    | 'CHANGED_ONLY'
    | 'SINGLE_EMP';

// HasSystemPw is not in the database but will still come from backend
// Create an extended class so typescript is happy
export class AltinnExtended extends Altinn {
    HasSystemPw: boolean;
}

@Injectable({ providedIn: 'root' })
export class AltinnIntegrationService extends BizHttp<Altinn> {
    public languages: any = [
        { ID: '1044' || null, text: 'Norsk (bokmål)' },
        { ID: '2068', text: 'Norsk (nynorsk)' },
        { ID: '1033', text: 'English' },
        { ID: '1083', text: 'Samisk' },
    ];
    public loginTypes: { ID: number; text: string }[] = [
        { ID: 1 || null || 0, text: 'AltinnPin' },
        { ID: 2, text: 'SMSPin' },
        { ID: 3, text: 'TaxPin' },
    ];

    constructor(
        http: UniHttp,
        private altinnAuthenticationService: AltinnAuthenticationService,
    ) {
        super(http);
        super.disableCache();

        this.relativeURL = Altinn.RelativeUrl;
        this.entityType = Altinn.EntityType;
    }

    public checkLogin(): Observable<boolean> {
        if (this.altinnAuthenticationService.hasImplicitAuth) {
            return of(true);
        }
        return super.GetAction(null, 'checklogin');
    }

    public setPassword(password: string): Observable<string> {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(this.relativeURL + '?action=setpassword&password=' + password)
            .send()
            .pipe(map((response) => response.body));
    }

    public sendTaxRequestAction(
        option: TaxRequestOption,
        empId: number = 0,
        empsAndChanged: boolean = false,
        categoryID: number = 0,
    ): Observable<AltinnReceipt> {
        return this.PostAction(
            1,
            'sendtaxrequest',
            'option=' +
                option +
                '&empId=' +
                empId +
                '&requestAllChanges=' +
                empsAndChanged +
                '&categoryID=' +
                categoryID,
        );
    }

    public requestTax(
        employeeIDs: number[],
        empsAndChanged: boolean = false,
        params?: string,
    ): Observable<AltinnReceipt> {
        return this.ActionWithBody(
            null,
            employeeIDs,
            'request-tax',
            RequestMethod.Post,
            `requestAllChanges=${empsAndChanged}${params ? params : ''}`,
        );
    }

    public requestTaxChanges(params?: string): Observable<AltinnReceipt> {
        return this.PostAction(null, 'request-tax-changes', params ? params : '');
    }

    public sendReconciliationRequest(option: A06Options): Observable<AltinnReceipt> {
        return this.ActionWithBody(null, option, 'send-a06-request', RequestMethod.Post);
    }

    public sendReconciliationRequestV2(option: A06Options): Observable<AltinnReceipt> {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withBody(option)
            .withEndPoint('altinn?action=send-a06-request&api=altinn2')
            .send()
            .pipe(map((res) => res.body));
    }

    public sendBarnepass(leveranse: BarnepassLeveranse): Observable<AltinnReceipt> {
        return this.ActionWithBody(null, leveranse, 'send-barnepass', RequestMethod.Post);
    }

    public isBarnepassSendt(year: number): Observable<boolean> {
        return super.GetAction(null, 'is-barnepass-sendt&year=' + year);
    }

    public getBarnepass(year: number): Observable<BarnepassLeveranse> {
        return super.GetAction(null, 'get-barnepass&year=' + year);
    }

    public emailBarnepassToCustomers(barnepassID: number, customers: any[]) {
        /*
        hvis alle - customers = tom
        */
        return super.ActionWithBody(barnepassID, customers, 'email-barnepass-to-customers', RequestMethod.Put);
    }

    public sendSelfEmployed(data: ThirdPartyData): Observable<AltinnReceipt> {
        return this.ActionWithBody(null, data, 'send-selfemployed', RequestMethod.Post);
    }

    public readTaxCard(authData: AltinnAuthenticationData, receiptID: number): Observable<TaxCardReadStatus> {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withHeaders(this.getHeaders(authData))
            .withEndPoint(`employees/?action=read-tax-cards&receiptID=${receiptID}`)
            .send()
            .pipe(map((response) => response.body));
    }

    public readTaxCardWithoutHeaders(receiptID: number): Observable<TaxCardReadStatus> {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(`employees/?action=read-tax-cards&receiptID=${receiptID}`)
            .send()
            .pipe(map((response) => response.body));
    }

    public getA07Response(authData: AltinnAuthenticationData | any = null, receiptID: number) {
        if (authData?.userID) {
            this.http.withHeaders(this.getHeaders(authData));
        }
        return this.GetAction(null, 'get-a07-response', `receiptID=${receiptID}`);
    }

    private getHeaders(authData: AltinnAuthenticationData): any {
        return {
            'x-altinn-userid': authData.userID,
            'x-altinn-password': authData.password,
            'x-altinn-pinmethod': authData.preferredLogin,
            'x-altinn-pin': authData.pin,
        };
    }

    getAltinnSystemLoginData(): Observable<any> {
        return this.GetAll('').pipe(
            switchMap((altinn: AltinnExtended[]) => (altinn.length ? of(altinn[0]) : this.GetNewEntity([], 'altinn'))),
        );
    }
}
