import { Component, EventEmitter } from '@angular/core';
import { AltinnAuthenticationData } from '@app/models/AltinnAuthenticationData';
import { ErrorService } from '@app/services/common/errorService';
import { AltinnAuthRequest } from '@uni-entities';
import { ISelectConfig } from '@uni-framework/ui/uni-select/select';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { Observable } from 'rxjs';
import { theme, THEMES } from 'src/themes/theme';

@Component({
    selector: 'altinn-authentication-modal',
    templateUrl: './altinn-authentication-modal.html',
    styleUrls: ['./altinn-authentication-modal.sass'],
})
export class AltinnAuthenticationModal2 implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter<AltinnAuthenticationData>();

    authData = new AltinnAuthenticationData();

    modalConfig: {
        getPinMessage: (request: AltinnAuthRequest) => Observable<any>;
    };

    pinOptions = [{ option: 'SMSPin' }, { option: 'TaxPin' }, { option: 'AltinnPin' }];
    pinSelectConfig: ISelectConfig = {
        valueProperty: 'option',
        displayProperty: 'option',
        emitValueInsteadOfObject: true,
        searchable: false,
        hideDeleteButton: true,
    };

    busy: boolean;
    step: 'login' | 'pin' = 'login';

    helpUrl =
        theme.theme !== THEMES.EXT02
            ? 'https://help.unieconomy.no/lonn/problemer-med-paalogging-ved-henting-av-tilbakemelding/skattekort-fra-altinn'
            : 'https://hjelp.dnbregnskap.dnb.no/no/article/integrasjon-med-altinn-1llaxg1/';

    altinnMessage: string;
    altinnMessageIsError: boolean;

    constructor(private errorService: ErrorService) {}

    ngOnInit() {
        this.modalConfig = this.options?.modalConfig;
        if (!this.modalConfig?.getPinMessage) {
            this.onClose.emit();
        }

        this.authData.preferredLogin = this.pinOptions && this.pinOptions[0]?.option;
    }

    submitCurrentStep() {
        if (this.step === 'login') {
            this.login();
        } else {
            this.confirmPin();
        }
    }

    login() {
        this.altinnMessage = undefined;

        const body = <AltinnAuthRequest>{
            UserID: this.authData.userID,
            UserPassword: this.authData.password,
            PreferredLogin: this.authData.preferredLogin,
        };

        if (body.UserID && body.UserPassword && body.PreferredLogin) {
            this.busy = true;
            this.modalConfig.getPinMessage(body).subscribe(
                (res) => {
                    this.authData.validTo = res.ValidTo;
                    this.authData.validFrom = res.ValidFrom;

                    this.altinnMessage = res.Message;
                    this.altinnMessageIsError = [1, 2, 3, 4, 5, 6].includes(res.Status);

                    this.step = 'pin';
                    this.busy = false;
                },
                (err) => {
                    const message = this.errorService.extractMessage(err) || '';
                    if (message && message.length < 200) {
                        this.altinnMessage = message;
                        this.altinnMessageIsError = true;
                    } else {
                        this.errorService.handle(err);
                    }

                    this.busy = false;
                },
            );
        }
    }

    confirmPin() {
        this.onClose.emit(this.authData);
    }
}
