<section role="dialog" class="uni-modal">
    <header>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path
                    fill="currentColor"
                    d="M13.5,14.8c0.7,0,1.3-0.6,1.3-1.3v-3c0-0.7-0.6-1.3-1.3-1.3h-3c-0.7,0-1.3,0.6-1.3,1.3v3c0,0.7,0.6,1.3,1.3,1.3H13.5z"
                />
                <path
                    fill="currentColor"
                    d="M21.3,2.3H3c-0.5,0-1,0.4-1,1v0.3c0,0.5,0.4,1,1,1h16.8v11.3c0,0.5,0.4,1,1,1H21c0.5,0,1-0.4,1-1V4.6V3C22,2.6,21.7,2.3,21.3,2.3z"
                />
                <path
                    fill="currentColor"
                    d="M21,19.4H4.3V8.1c0-0.5-0.4-1-1-1H3c-0.5,0-1,0.4-1,1v11.3v1.5c0,0.4,0.3,0.8,0.8,0.8H21c0.5,0,1-0.4,1-1v-0.3C22,19.8,21.6,19.4,21,19.4z"
                />
            </g>
        </svg>
        Altinn pålogging

        <a class="pull-right" [href]="helpUrl" target="_blank"> Hjelp </a>
    </header>

    <article (keydown.enter)="submitCurrentStep()">
        <section *ngIf="altinnMessage" class="alert" [ngClass]="altinnMessageIsError ? 'bad' : 'info'">
            <span>{{ altinnMessage }}</span>
        </section>

        <!-- Step 1 -->
        <ng-container *ngIf="step === 'login'">
            <label class="uni-label">
                <span>Fødselsnummer/brukernavn</span>
                <input [(ngModel)]="authData.userID" uniAutoFocus type="text" />
            </label>

            <label class="uni-label">
                <span>Passord</span>
                <input [(ngModel)]="authData.password" type="password" />
            </label>

            <label class="uni-label">
                <span>Type pinkode</span>
                <uni-select [(value)]="authData.preferredLogin" [items]="pinOptions" [config]="pinSelectConfig">
                </uni-select>
            </label>
        </ng-container>

        <!-- Step 2 -->
        <ng-container *ngIf="step === 'pin'">
            <label *ngIf="step === 'pin'" class="uni-label">
                <span>Pin kode</span>
                <input [(ngModel)]="authData.pin" uniAutoFocus type="text" />
            </label>
        </ng-container>
    </article>

    <footer>
        <button class="secondary" (click)="onClose.emit()">Avbryt</button>
        <button class="c2a" (click)="submitCurrentStep()" [attr.aria-busy]="busy">
            {{ step === 'login' ? 'Neste' : 'Fullfør' }}
        </button>
    </footer>
</section>
