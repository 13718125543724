import { Injectable } from '@angular/core';
import { BizHttp, UniHttp } from '@uni-framework/core/http';
import { EmployeeLeave, Leavetype } from '@uni-entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { rigDate } from '@app/components/common/utils/rig-date';
import { FinancialYearService } from '../accounting/financialYearService';

@Injectable({ providedIn: 'root' })
export class EmployeeLeaveService extends BizHttp<EmployeeLeave> {
    private leaveTypes: { ID: number; text: string }[] = [
        { ID: Leavetype.NotSet, text: 'Ikke valgt' },
        { ID: Leavetype.LayOff, text: 'Permittering' },
        { ID: Leavetype.Leave_with_parental_benefit, text: 'Permisjon med foreldrepenger' },
        { ID: Leavetype.Military_service_leave, text: 'Permisjon ved militærtjeneste' },
        { ID: Leavetype.Educational_leave, text: 'Utdanningspermisjon - Utgått' },
        { ID: Leavetype.Compassionate_leave, text: 'Velferdspermisjon - Utgått' },
        { ID: Leavetype.Vacation, text: 'Ferie' },
        { ID: Leavetype.Self_report, text: 'Egenmelding' },
        { ID: Leavetype.Sick_leave_paid, text: 'Sykemelding' },
        { ID: Leavetype.Sick_leave_unpaid, text: 'Ubetalt sykefravær' },
        { ID: Leavetype.Sick_child, text: 'Sykt barn' },
        { ID: Leavetype.Educational_leave_not_by_law, text: 'Utdanningspermisjon (Ikke lovfestet)' },
        { ID: Leavetype.Educational_leave_by_law, text: 'Utdanningspermisjon (Lovfestet)' },
        { ID: Leavetype.Compassionate_leave_not_by_law, text: 'Andre ikke-lovfestede permisjoner' },
        { ID: Leavetype.Compassionate_leave_by_law, text: 'Andre lovfestede permisjoner' },
    ];

    constructor(
        http: UniHttp,
        private yearService: FinancialYearService,
    ) {
        super(http);
        this.relativeURL = EmployeeLeave.RelativeUrl;
        this.entityType = EmployeeLeave.EntityType;
    }

    getEmployeeLeaveDetails(employeeID: number): Observable<any> {
        const year = this.yearService.getActiveFinancialYear().Year;
        return super.GetAction(
            null,
            'count-employee-leave-for-employee',
            `employeeID=${employeeID}&startDate=${year}-01-01&endDate=${year}-12-31`,
        );
    }

    getEmployeeLeaveDetailsForCompany(): Observable<any> {
        const year = this.yearService.getActiveFinancialYear().Year;
        return super.GetAction(
            null,
            'count-employee-leave-for-company',
            `startDate=${year}-01-01&endDate=${year}-12-31`,
        );
    }

    getEmployeeSelfReportDays(employeeID: number, startDate?: Date, endDate?: Date): Observable<any> {
        const today = rigDate(new Date());
        const endDateFormated = (endDate ? rigDate(endDate) : today).format('YYYY-MM-DD');
        const startDateFormated = (startDate ? rigDate(startDate) : rigDate(today).add(-1, 'years')).format(
            'YYYY-MM-DD',
        );
        return super.GetAction(
            null,
            'count-self-report-days-in-period',
            `employeeID=${employeeID}&startDate=${startDateFormated}&endDate=${endDateFormated}`,
        );
    }

    postWithLeaveError(employeeLeave: EmployeeLeave): Observable<any> {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}/?action=post-new-employee-leave`)
            .withBody(employeeLeave)
            .send()
            .pipe(map((response) => response.body));
    }

    saveMultipleLeave(leaves: EmployeeLeave[]): Observable<any> {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}/?action=post-multiple-employee-leave`)
            .withBody(leaves)
            .send()
            .pipe(map((response) => response.body));
    }

    importEmployeeLeaveFromExcel(fileId: number): Observable<any> {
        return super.PostAction(null, 'import-employee-leaves', `fileID=${fileId}`);
    }

    getOnlyNewTypes() {
        return this.leaveTypes.filter(
            (x) =>
                x.ID !== Leavetype.Leave &&
                (x.ID < Leavetype.Educational_leave || x.ID >= Leavetype.Educational_leave_not_by_law),
        );
    }

    getLeaveType(id: Number) {
        return this.leaveTypes.find((x) => x.ID === id);
    }

    getAllTypes() {
        return this.leaveTypes;
    }

    getAllTypesForRegistration() {
        return this.leaveTypes.filter(
            (l) => l.ID != Leavetype.Educational_leave && l.ID != Leavetype.Compassionate_leave,
        ); //Removes obsolete types
    }

    getLostWorkDays(leave): number {
        let lostDays = 0;
        let current = rigDate(leave.FromDate);
        const to = rigDate(leave.ToDate);

        if (!leave.ToDate || !leave.FromDate || to.isBefore(current)) {
            return lostDays;
        }

        while (current.isSameOrBefore(to)) {
            if (current.isoWeekday() <= 5) {
                lostDays++;
            }
            current = current.add(1, 'day');
        }

        return lostDays;
    }
}
