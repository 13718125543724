import { rigDate } from '@app/components/common/utils/rig-date';

export class AltinnAuthenticationData {
    public userID: string;
    public password: string;
    public preferredLogin: string;
    public pin: string;
    public validTo: Date;
    public validFrom: Date;

    public static fromObject(obj: any): AltinnAuthenticationData {
        const instance = new AltinnAuthenticationData();
        Object.assign(instance, obj);
        return instance;
    }

    public isValid(): boolean {
        const now = rigDate().add(1, 'minutes');
        return !!this.pin && rigDate(this.validTo).isAfter(now);
    }
}
