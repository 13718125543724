import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { IUniModal, IModalOptions } from '@uni-framework/uni-modal/interfaces';
import { SubEntity } from '@uni-entities';
import { UniFieldLayout, FieldType } from '@uni-framework/ui/uniform';
import { IMuniAGAZone } from '@app/services/common/subEntityService';

@Component({
    selector: 'uni-edit-aga-zone-modal',
    templateUrl: './editSubEntityAgaZoneModal.html',
    styleUrls: ['./editSubEntityAgaZoneModal.sass'],
})
export class EditSubEntityAgaZoneModal implements OnInit, IUniModal {
    @Output() onClose: EventEmitter<SubEntity[]> = new EventEmitter();
    @Input() options?: IModalOptions;

    model: any[];
    fields: any[];

    ngOnInit() {
        this.model = this.setDefaults(this.options.data.subEntities, this.options.data.municipalAgaZones);
        this.fields = this.getFields(this.options.data.subEntities, this.options.data.municipalAgaZones);
    }

    close() {
        this.onClose.emit(this.model);
    }

    private setDefaults(subEntities: SubEntity[], municipalAgaZones: IMuniAGAZone[]) {
        return subEntities.map((subEntity) => {
            const defaultZone = municipalAgaZones.find(
                (zone) => zone.MunicipalityNo === subEntity.MunicipalityNo && zone.ZoneName === '1',
            );
            if (!!defaultZone) {
                subEntity.AgaZone = defaultZone.ZoneID;
            }
            return subEntity;
        });
    }

    private getFields(subEntities: SubEntity[], municipalAgaZones: IMuniAGAZone[]) {
        return subEntities
            .map((subEntity, i) =>
                this.getFieldIfNeeded(
                    subEntity,
                    municipalAgaZones.filter((muni) => muni.MunicipalityNo === subEntity.MunicipalityNo),
                    `[${i}].AgaZone`,
                ),
            )
            .filter((field) => !!field);
    }

    private getFieldIfNeeded(subEntity: SubEntity, municipalAgaZones: IMuniAGAZone[], name: string): UniFieldLayout {
        if (municipalAgaZones.length < 2) {
            return null;
        }
        const label = subEntity.BusinessRelationInfo && subEntity.BusinessRelationInfo.Name;
        return <UniFieldLayout>{
            Property: name,
            Label: `${subEntity.OrgNumber} - ${label}`,
            FieldType: FieldType.DROPDOWN,
            Options: {
                source: municipalAgaZones,
                template: (muniAga: IMuniAGAZone) => `${muniAga.ZoneName}`,
                valueProperty: `ZoneID`,
            },
        };
    }
}
