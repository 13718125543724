import { Injectable } from '@angular/core';
import { BizHttp } from '../../../../framework/core/http/BizHttp';
import { UniHttp } from '../../../../framework/core/http/http';
import { VacationPayLine, WageDeductionDueToHolidayType } from '../../../unientities';
import { Observable, of } from 'rxjs';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { RequestMethod } from '@uni-framework/core/http';
import { UniMath } from '@uni-framework/core/uniMath';

export interface IVacationPaySummary {
    rate: number;
    rate60: number;
    wageDeductionDueToHolidayDescription: string;
    hasTwoLineVacationPayDescription: string;
    basicAmountDesc: string;
    basicAmount: number;
    allowOver6G: boolean;
    mainAccountCostVacationDescription: string;
    mainAccountAllocatedVacationDescription: string;
}

@Injectable({ providedIn: 'root' })
export class VacationpayLineService extends BizHttp<VacationPayLine> {
    constructor(
        protected http: UniHttp,
        private yearService: FinancialYearService,
    ) {
        super(http);
        this.relativeURL = VacationPayLine.RelativeUrl;
        this.entityType = VacationPayLine.EntityType;
    }

    public getVacationpayBasis(
        year: number = 0,
        payrun: number = 0,
        showAllEmps?: boolean,
    ): Observable<VacationPayLine[]> {
        return super.GetAction(
            null,
            'lines',
            `payrunID=${payrun}&year=${year || this.yearService.getActiveYear()}&showAll=${showAllEmps}`,
        );
    }

    public save(line: VacationPayLine): Observable<VacationPayLine> {
        return line.ID ? super.Put(line.ID, line) : super.Post(line);
    }

    public toSalary(baseYear: number, run: number, payList: VacationPayLine[]): Observable<boolean> {
        if (!payList.length) {
            return of(true);
        }
        super.invalidateCache();
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`${this.relativeURL}?action=to-salary&payrollID=${run}&baseYear=${baseYear}`)
            .withBody(payList)
            .send();
    }

    public close(payrollrunID: number = 0, employeeIDList: number[]): Observable<boolean> {
        if (!employeeIDList.length) {
            return of(false);
        }
        return super.ActionWithBody(null, employeeIDList, 'close', RequestMethod.Put, `payrollID=${payrollrunID}`);
    }

    public getWageDeductionDueToHoliday(hasTwoLineVacationPay: boolean): any[] {
        let wageDeductionDueToHolidayList: { id: WageDeductionDueToHolidayType; name: string; description: string }[] =
            [
                { id: WageDeductionDueToHolidayType.Deduct4PartsOf26, name: '-4/26 av månedslønn', description: '' },
                { id: WageDeductionDueToHolidayType.Add1PartOf26, name: '+1/26 av månedslønn', description: '' },
                { id: WageDeductionDueToHolidayType.Deduct3PartsOf22, name: '-3/22 av månedslønn', description: '' },
            ];
        if (hasTwoLineVacationPay) {
            wageDeductionDueToHolidayList = [
                {
                    id: WageDeductionDueToHolidayType.Deduct4PartsOf26,
                    name: 'Beregning: 30/26',
                    description: `6 dagers arbeidsuke. Bruk med sats: 12 %, ferie: 5 uker`,
                },
                {
                    id: WageDeductionDueToHolidayType.Add1PartOf26,
                    name: 'Beregning: 25/26',
                    description: `6 dagers arbeidsuke. Bruk med sats: 10,2 %, ferie: 4 uker + 1 dag`,
                },
                {
                    id: WageDeductionDueToHolidayType.Deduct3PartsOf22,
                    name: 'Beregning: 25/22',
                    description: `5 dagers arbeidsuke. Bruk med sats: 12 %, ferie 5 uker`,
                },
                {
                    id: WageDeductionDueToHolidayType.Deduct21PartsOf22TwoLines,
                    name: 'Beregning: 21/22',
                    description: `5 dagers arbeidsuke. Bruk med sats: 10,2 %, ferie: 4 uker + 1 dag`,
                },
                {
                    id: WageDeductionDueToHolidayType.Deduct25PartsOf21_67TwoLines,
                    name: 'Beregning: 25/21,67',
                    description: `5 dagers arbeidsuke. Bruk med sats: 12 %, ferie: 5 uker`,
                },
                {
                    id: WageDeductionDueToHolidayType.Deduct21PartsOf21_67TwoLines,
                    name: 'Beregning: 21/21,67',
                    description: `5 dagers arbeidsuke. Bruk med sats: 10,2 %, ferie: 4 uker + 1 dag`,
                },
            ];
        }
        return wageDeductionDueToHolidayList;
    }

    public getWageDeductionDueToHolidayName(
        deduction: WageDeductionDueToHolidayType,
        hasTwoLineVacationPay: boolean,
    ): string {
        return (
            this.getWageDeductionDueToHoliday(hasTwoLineVacationPay).find((x) => x.id === deduction)?.name ??
            'Ikke valgt'
        );
    }

    public calcVacation(base: number, rate: number) {
        const ofset = 10000;
        return (UniMath.round(base * ofset, 0) * UniMath.round(rate * ofset, 0)) / (100 * Math.pow(ofset, 2));
    }
}
